exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ansprechpartner-tsx": () => import("./../../../src/pages/ansprechpartner.tsx" /* webpackChunkName: "component---src-pages-ansprechpartner-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-gewerbeflaechen-tsx": () => import("./../../../src/pages/gewerbeflaechen.tsx" /* webpackChunkName: "component---src-pages-gewerbeflaechen-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-standort-kyritz-tsx": () => import("./../../../src/pages/standort-kyritz.tsx" /* webpackChunkName: "component---src-pages-standort-kyritz-tsx" */),
  "component---src-pages-unternehmen-tsx": () => import("./../../../src/pages/unternehmen.tsx" /* webpackChunkName: "component---src-pages-unternehmen-tsx" */),
  "component---src-templates-company-index-tsx": () => import("./../../../src/templates/company/index.tsx" /* webpackChunkName: "component---src-templates-company-index-tsx" */),
  "component---src-templates-news-article-index-tsx": () => import("./../../../src/templates/news-article/index.tsx" /* webpackChunkName: "component---src-templates-news-article-index-tsx" */)
}

